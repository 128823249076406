const home = {

    init() {
        this.initEvent();
    },

    initEvent() {
        jQuery(document).on('ready', this.playVideo.bind(this));
        jQuery(document).on('click', '.z-category__mini-tab', this.handleScrollClick.bind(this));
    },
    playVideo(){
        let video = document.querySelectorAll('.z-category__visual')
        let video_mini = document.querySelectorAll('.z-category__mini')

        for(let i = 0; i < video.length; i++){
            if(video[i].tagName === 'VIDEO'){
                video[i].play()
                video_mini[i].play()
            }
        }
    },

    handleScrollClick(e) {
        const miniature = e.currentTarget;
        let index = miniature.getAttribute('id')
        window.scrollTo(0, parseInt(index) * window.innerHeight + window.innerHeight)
    },
}

home.init();